import {
  Table,
  Paper,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";

import { ITag } from "../../interface";
import { useFetchTags } from "../../../../hooks";
import { TagTableSkeleton } from "../../components";
import { ActionDialog, TagAction } from "./components";
import { TableCell } from "../../components/table-cell";
import { updateSelectedTag } from "../../../../store/slices/cmsSlice";

export default function TagTable() {
  const dispatch = useDispatch();
  const query = useFetchTags();
  const [action, setAction] = useState(TagAction.NONE);

  const handleAddTag = () => {
    dispatch(updateSelectedTag(undefined));
    setAction(TagAction.ADD);
  };

  const handleEditTag = (tag: ITag, isEdit: boolean) => {
    dispatch(updateSelectedTag(tag));
    setAction(isEdit ? TagAction.EDIT : TagAction.DELETE);
  };

  return (
    <Box width="100%" sx={{ display: "flex", flexDirection: "column" }}>
      {/* Add + Refresh buttons */}
      <Box
        sx={{
          mb: 1,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Tooltip title="Tạo tag" placement="top">
          <IconButton color="primary" onClick={handleAddTag}>
            <AddIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Refresh" placement="top">
          <IconButton
            color="primary"
            sx={{ ml: 2 }}
            onClick={() => query.refetch()}
          >
            <RefreshIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Tooltip>
      </Box>

      {query.tags?.length > 0 && !query.isLoading && (
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 600,
              backgroundColor: "#222129",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell content="ID" alignLeft isHeader />
                <TableCell content="Name" isHeader />
                <TableCell content="Label" isHeader />
                <TableCell content="Post count" isHeader />
                <TableCell content="Thao tác" isHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {query.tags?.map((tag: ITag) => (
                <TableRow
                  key={tag.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell content={tag.id} alignLeft />
                  <TableCell content={tag.name} />
                  <TableCell content={tag.label} />
                  <TableCell content={tag.postCount} />
                  <TableCell
                    action={
                      <>
                        <Tooltip title="Sửa">
                          <IconButton
                            color="primary"
                            onClick={() => handleEditTag(tag, true)}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {tag.postCount == 0 && (
                          <Tooltip title="Xoá">
                            <IconButton
                              color="error"
                              onClick={() => handleEditTag(tag, false)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    }
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {query.isLoading && <TagTableSkeleton />}

      {action !== TagAction.NONE && (
        <ActionDialog
          action={action}
          onSuccess={() => {
            setAction(TagAction.NONE);
            query.refetch();
          }}
          onClose={() => setAction(TagAction.NONE)}
        />
      )}
    </Box>
  );
}

export { TagTable };
