import { Backdrop, CircularProgress, Typography } from "@mui/material";

interface IProps {
  title: string;
}

const LoadingOverlay = ({ title }: IProps) => {
  return (
    <Backdrop
      open
      onClick={() => {}}
      sx={{
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="primary" />
      <Typography mt={4} color={"white"}>
        {title}
      </Typography>
    </Backdrop>
  );
};

export { LoadingOverlay };
