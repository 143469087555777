import { useSelector } from "react-redux";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import {
  AboutContainer,
  CmsContainer,
  GalleryContainer,
  HomeContainer,
  NotFoundContainer,
  PostContainer,
} from "../containers";
import { CMSLayout } from "../layout";
import { EditorPage } from "../containers/cms/components";
import { selectLoggedInState } from "../store/slices/appSlice";

const AppRouter = () => {
  const isAuth = useSelector(selectLoggedInState);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        <Route path="/gallery" element={<GalleryContainer />} />
        <Route path="/about" element={<AboutContainer />} />
        <Route path="/blog/:postSlug" element={<PostContainer />} />
        <Route
          path="/cms"
          element={
            <CMSLayout>
              <Outlet />
            </CMSLayout>
          }
        >
          <Route index element={<CmsContainer />} />
          {isAuth && (
            <>
              <Route path="post/*" element={<EditorPage about={false} />} />
              <Route path="about/edit" element={<EditorPage about />} />
            </>
          )}
        </Route>
        <Route path="*" element={<NotFoundContainer />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
