import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import { MainLayout } from "../../layout";
import DATA from "./components/post-list/data";
import { useDocumentTitle } from "../../hooks";
import { ListSkeleton, LoadMoreButton, PostList } from "./components";

const HomeContainer = () => {
  useDocumentTitle("Bloggy");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      const delay = setTimeout(() => {
        setIsLoading(false);
        clearTimeout(delay);
      }, 1000);
    };

    fetchData();
  }, []);

  return (
    <MainLayout>
      <Box my={7} />
      {isLoading ? (
        <ListSkeleton />
      ) : (
        <>
          <PostList data={DATA} />
          <LoadMoreButton />
        </>
      )}
    </MainLayout>
  );
};

export { HomeContainer };
