import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";

import { StyledInput } from "../input";
import { AppButton } from "../../../../components";
import { updateLoggedInState } from "../../../../store/slices/appSlice";

interface IFormInput {
  username: string;
  password: string;
}

const LoginForm = () => {
  const theme = useTheme();
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();
  const dispatch = useDispatch();
  const { REACT_APP_CMS_USERNAME, REACT_APP_CMS_PASSWORD } = process.env;

  const onSubmit = (data: IFormInput) => {
    if (
      REACT_APP_CMS_USERNAME === data.username &&
      REACT_APP_CMS_PASSWORD === data.password
    ) {
      Cookies.set("accessToken", "true");
      dispatch(updateLoggedInState(true));
    } else {
      setError("root", {
        type: "custom",
        message: "Wrong username or password!",
      });
    }
  };

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <StyledInput
        autoFocus
        theme={theme}
        label="Username"
        variant="outlined"
        formopt={{ ...register("username") }}
      />
      <StyledInput
        theme={theme}
        type="password"
        label="Password"
        variant="outlined"
        sx={{ mt: 3.5 }}
        autoComplete="current-password"
        formopt={{ ...register("password") }}
      />
      <Typography color={theme.palette.error.main} mt={3}>
        {errors.root?.message}
      </Typography>
      <AppButton
        type="submit"
        variant="contained"
        sx={{
          mt: 4,
          py: 1.5,
          width: "36%",
          borderRadius: 0.6,
        }}
      >
        <Typography color={"black"} fontWeight="bold">
          Login
        </Typography>
      </AppButton>
    </Box>
  );
};

export { LoginForm };
