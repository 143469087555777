import { ReactNode } from "react";
import { Typography, TableCell as MuiTableCell } from "@mui/material";

interface IProps {
  content?: string | number;
  action?: ReactNode;
  alignLeft?: boolean;
  isHeader?: boolean;
}

const TableCell = ({ content, action, alignLeft, isHeader }: IProps) => {
  return (
    <MuiTableCell sx={{ color: "white" }} align={alignLeft ? "left" : "right"}>
      <Typography fontWeight={isHeader ? "bold" : "400"}>{content}</Typography>
      {action ?? action}
    </MuiTableCell>
  );
};

export { TableCell };
