import { Box } from "@mui/material";
import { AppSkeleton } from "../../../../components";

const TagTableSkeleton = () => {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {Array(4)
        .fill(0)
        .map((_, index) => {
          return (
            <AppSkeleton
              key={index.toString()}
              sx={{ mb: 3 }}
              height={60}
              width="100%"
              variant="rectangular"
            />
          );
        })}
    </Box>
  );
};

const PostTableSkeleton = () => {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {Array(5)
        .fill(0)
        .map((_, index) => {
          return (
            <AppSkeleton
              key={index.toString()}
              sx={{ mb: 3 }}
              height={100}
              width="100%"
              variant="rectangular"
            />
          );
        })}
    </Box>
  );
};

export { TagTableSkeleton, PostTableSkeleton };
