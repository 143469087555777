import { Box } from "@mui/material";

import { MainLayout } from "../../layout";
import { AboutDetail } from "./components";
import { useDocumentTitle } from "../../hooks";

const AboutContainer = () => {
  useDocumentTitle("Bloggy :: About");

  return (
    <MainLayout>
      <Box my={7} />
      <AboutDetail />
    </MainLayout>
  );
};

export { AboutContainer };
