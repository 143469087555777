import { Box } from "@mui/material";
import { AppSkeleton } from "../../../../components";

const ListSkeleton = () => {
  return (
    <>
      {["", "", ""].map((_, index) => {
        return (
          <Box
            key={index.toString()}
            sx={{ mb: 9, display: "flex", flexDirection: "column" }}
          >
            <AppSkeleton height={30} width="50%" variant="rectangular" />
            <AppSkeleton
              height={16}
              width="100%"
              variant="rectangular"
              style={{ marginTop: 16 }}
            />
            <AppSkeleton
              height={16}
              width={"14%"}
              variant="rectangular"
              style={{ marginTop: 16 }}
            />
            <AppSkeleton
              height={16}
              width={"40%"}
              variant="rectangular"
              style={{ marginTop: 16 }}
            />
            <AppSkeleton
              height={76}
              width={"100%"}
              variant="rectangular"
              style={{ marginTop: 32 }}
            />
          </Box>
        );
      })}
    </>
  );
};

export { ListSkeleton };
