import { Theme, styled } from "@mui/material/styles";
import { TextField, TextFieldProps } from "@mui/material";
import { UseFormRegisterReturn } from "react-hook-form";

type IProps = TextFieldProps & {
  theme: Theme;
  shrinking?: boolean;
  formopt?: UseFormRegisterReturn<any>;
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: theme.palette.primary.main,
      borderColor: "#fff",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledInput = (props: IProps) => {
  const { shrinking, ...rest } = props;

  const sx = {
    ...props.sx,
    width: "36%",
    [rest.theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  };

  return (
    <StyledTextField
      {...rest}
      sx={sx}
      {...rest.formopt}
      InputLabelProps={{
        shrink: shrinking,
        style: { color: "#fff" },
      }}
      InputProps={{
        style: { color: "#fff", borderRadius: 0 },
      }}
    />
  );
};

export { StyledInput };
