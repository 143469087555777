import { createSlice } from "@reduxjs/toolkit";
import { isAuthenticated } from "../../utils";

interface IInitalState {
  isLoggedIn: boolean;
  cmsActiveTab: number;
}

const initialState: IInitalState = {
  isLoggedIn: isAuthenticated(),
  cmsActiveTab: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateLoggedInState: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    updateCmsActiveTab: (state, action) => {
      state.cmsActiveTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateLoggedInState, updateCmsActiveTab } = appSlice.actions;

export const selectLoggedInState = (state: any) => state.app.isLoggedIn;
export const selectCmsActiveTab = (state: any) => state.app.cmsActiveTab;

export default appSlice.reducer;
