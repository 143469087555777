import { createSlice } from "@reduxjs/toolkit";
import { IPost } from "../../containers/home/interfaces";

interface IInitalState {
  post: {
    list: IPost[];
    pagination: any;
  };
  viewingPost: {
    id: String;
    slug: String;
    title: String;
  };
}

const initialState: IInitalState = {
  post: {
    list: [],
    pagination: {},
  },
  viewingPost: {
    id: "",
    slug: "",
    title: "",
  },
};

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    updatePostList: (state, action) => {
      state.post = action.payload;
    },
    updateViewingPost: (state, action) => {
      state.viewingPost = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePostList, updateViewingPost } = postSlice.actions;

export const selectPostList = (state: any) => state.post.post.list;
export const selectViewingPost = (state: any) => state.post.viewingPost;

export default postSlice.reducer;
