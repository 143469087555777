import { useEffect } from "react";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, useTheme } from "@mui/material";

import {
  selectAboutContent,
  updateAboutContent,
} from "../../../../store/slices/aboutSlice";
import { getAboutContent } from "../../api";
import { AboutSkeleton } from "../skeleton";
import { DanhAvatar } from "../../../../assets";
import { QUERY_KEYS } from "../../../../constants";

const AboutDetail = () => {
  const theme = useTheme();
  const content = useSelector(selectAboutContent);
  const dispatch = useDispatch();
  const info = useQuery({
    queryKey: [QUERY_KEYS.GET_ABOUT],
    queryFn: getAboutContent,
    retry(failureCount, error) {
      return false;
    },
    staleTime: Infinity,
  });

  useEffect(() => {
    if (info?.data && info.isSuccess) {
      if (!content) {
        dispatch(updateAboutContent(info.data?.data?.content));
      }
    }
  }, [info?.data]);

  return (
    <Box
      sx={{
        mb: 6,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("md")]: {
          alignItems: "center",
          flexDirection: "column",
        },
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          mr: 6,
          width: 168,
          height: 168,
          display: "flex",
          borderRadius: 84,
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.down("md")]: {
            mr: 0,
            mb: 4,
          },
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Avatar
          src={DanhAvatar}
          alt="danhmc-avatar"
          sx={{ width: 160, height: 160, objectFit: "cover" }}
        />
      </Box>
      {info.isLoading ? (
        <AboutSkeleton />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {parse(content)}
        </Box>
      )}
    </Box>
  );
};

export { AboutDetail };
