import { Box } from "@mui/material";

import { PostDetail } from "./components";
import { MainLayout } from "../../layout";

const PostContainer = () => {
  return (
    <MainLayout>
      <Box my={7} />
      <PostDetail />
    </MainLayout>
  );
};
export { PostContainer };
