import {
  ICreatePostPayload,
  ISaveContentPayload,
  ITag,
  ITagPayload,
} from "../interface";
import axiosClient from "../../../utils/axios";

// ABOUT
export const getAboutContent = async () => {
  return await axiosClient.get(`/about`);
};

export const saveAboutContent = async (payload: ISaveContentPayload) => {
  return await axiosClient.put(`/about`, payload);
};

// TAG
export const getAllTags = async () => {
  return await axiosClient.get(`/tags`);
};

export const createNewTag = async (payload: ITagPayload) => {
  return await axiosClient.post(`/tags`, payload);
};

export const editTag = async (payload: ITag) => {
  return await axiosClient.put(`/tags`, payload);
};

export const deleteTag = async (payload: { id: number }) => {
  return await axiosClient.delete(`/tags`, payload);
};

// POST
export const getAllPosts = async () => {
  return await axiosClient.get(`/posts`);
};

export const createPost = async (payload: ICreatePostPayload) => {
  return await axiosClient.post(`/posts`, payload);
};
