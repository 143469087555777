import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import { IPost } from "../../interface";
import { useFetchPosts } from "../../../../hooks";
import { PostTableSkeleton, TableCell } from "../../components";

const data = [
  {
    id: 1,
    slug: "",
    title: "Argentina lần đầu thua ở vòng loại World Cup 2026",
    content: "",
    viewCount: 10,
    publish: true,
    tags: ["Tam su", "Linh tinh"],
    createdAt: "2:04 PM 17 Nov",
  },
  {
    id: 2,
    slug: "",
    title: "Argentina lần đầu thua ở vòng loại World Cup 2026",
    content: "",
    viewCount: 0,
    publish: false,
    tags: ["Linh tinh", "Linh tinh"],
    createdAt: "2:04 PM 17 Nov",
  },
];

const PostTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list, isLoading } = useFetchPosts();

  const handlePostAction = (post: IPost, type: string) => {
    navigate(`/cms/post/${type}`);
  };

  return (
    <Box width={"100%"}>
      {/* Add + Refresh buttons */}
      <Box
        sx={{
          mb: 1,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Tooltip title="Tạo tag" placement="top">
          <Link to={"/cms/post/create"}>
            <IconButton color="primary">
              <AddIcon sx={{ fontSize: 28 }} />
            </IconButton>
          </Link>
        </Tooltip>

        <Tooltip title="Refresh" placement="top">
          <IconButton color="primary" sx={{ ml: 2 }} onClick={() => {}}>
            <RefreshIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Tooltip>
      </Box>

      {list?.length > 0 && (
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 600,
              backgroundColor: "#222129",
            }}
            aria-label="post-table"
          >
            <TableHead>
              <TableRow>
                <TableCell content="ID" alignLeft isHeader />
                <TableCell content="Title" alignLeft isHeader />
                <TableCell content="Tags" alignLeft isHeader />
                <TableCell content="Created at" alignLeft isHeader />
                <TableCell content="View count" isHeader />
                <TableCell content="Published" isHeader alignLeft />
                <TableCell content="Thao tác" isHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.map((post: any) => (
                <TableRow
                  key={post.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell content={post.id} alignLeft />
                  <TableCell content={post.title} alignLeft />
                  <TableCell content={post.tags[0]} alignLeft />
                  <TableCell content={post.createdAt} alignLeft />
                  <TableCell content={post.viewCount} />
                  <TableCell
                    alignLeft
                    action={
                      <Typography
                        color={
                          post.publish
                            ? theme.palette.primary.main
                            : theme.palette.grey[300]
                        }
                        fontWeight="bold"
                      >
                        {post.publish ? "TRUE" : "FALSE"}
                      </Typography>
                    }
                  />
                  <TableCell
                    action={
                      <>
                        <Tooltip title="Xem" placement="right">
                          <IconButton
                            color="primary"
                            onClick={() => handlePostAction(post, "view")}
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Sửa" placement="right">
                          <IconButton
                            color="primary"
                            onClick={() => handlePostAction(post, "edit")}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {isLoading && <PostTableSkeleton />}
    </Box>
  );
};

export { PostTable };
