import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import { AppButton } from "../button";

interface IHeaderProps {}

const getStyles = (theme: Theme) => ({
  container: {
    top: 0,
    zIndex: 10,
    margin: "auto",
    display: "flex",
    position: "sticky",
    paddingBottom: 1.5,
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
  },
  logo: {
    paddingTop: 0.4,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 0.4,
    backgroundColor: theme.palette.primary.main,
    ":hover": { backgroundColor: theme.palette.primary.main },
  },
  centerPattern: {
    width: "100%",
    background: `repeating-linear-gradient(
      90deg,
      ${theme.palette.primary.main},
      ${theme.palette.primary.main} 2px,
      transparent 0,
      transparent 10px
    )`,
  },
});

const Header = (props: IHeaderProps): React.JSX.Element => {
  const theme = useTheme();
  const style = getStyles(theme);
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  return (
    <Box sx={style.container}>
      <AppButton variant="contained" sx={style.logo} onClick={goToHome}>
        <Typography variant="h6" color={theme.palette.secondary.dark}>
          Bloggy
        </Typography>
      </AppButton>
      <Box style={style.centerPattern} />
      {/* <Box sx={style.logo} /> */}
    </Box>
  );
};

export { Header };
