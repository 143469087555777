import { Box } from "@mui/material";

import { MainLayout } from "../../layout";
import { NotFoundView } from "../../components";

const NotFoundContainer = () => {
  return (
    <MainLayout hasNavbar={false}>
      <Box my={6} />
      <NotFoundView />
      <Box my={5} />
    </MainLayout>
  );
};

export { NotFoundContainer };
