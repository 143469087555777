import { ReactNode } from "react";
import { Footer, Header, NavBar } from "../../components";

interface IProps {
  children: ReactNode;
  hasNavbar?: boolean;
}

const MainLayout = ({ children, hasNavbar = true }: IProps) => {
  return (
    <>
      <Header />
      {hasNavbar && <NavBar />}
      {children}
      <Footer />
    </>
  );
};

export { MainLayout };
