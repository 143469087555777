import { useEffect, useRef } from "react";

const useDocumentTitle = (title: string, prevailOnUnmount: boolean = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        window.scrollTo(0, 0);
        document.title = defaultTitle.current;
      }
    },
    []
  );
};

export { useDocumentTitle };
