import { configureStore } from "@reduxjs/toolkit";

import appReducer from "./slices/appSlice";
import postReducer from "./slices/postSlice";
import aboutReducer from "./slices/aboutSlice";
import cmsReducer from "./slices/cmsSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    cms: cmsReducer,
    post: postReducer,
    about: aboutReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export * from "./slices/postSlice";
