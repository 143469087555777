import { Box, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import SECTIONS from "./section";
import { AboutTab, PostTable } from "../tabs";
import TagTable from "../tabs/tag-table";
import {
  selectCmsActiveTab,
  updateCmsActiveTab,
  updateLoggedInState,
} from "../../../store/slices/appSlice";
import { AppButton } from "../../../components";
import { removeAccessToken } from "../../../utils";
import { Link } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

const CmsDashboard = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(selectCmsActiveTab);

  const logOut = () => {
    removeAccessToken();
    dispatch(updateLoggedInState(false));
  };

  const handleTabChange = (index: number) => {
    dispatch(updateCmsActiveTab(index));
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Tabs
        value={activeTab}
        onChange={(e, value) => handleTabChange(value)}
        aria-label="blogyy-cms-tabs"
      >
        {SECTIONS?.map((item) => {
          return (
            <Tab
              key={item.id}
              label={item.title}
              sx={{ fontSize: 18, color: "white" }}
            />
          );
        })}
      </Tabs>
      <CustomTabPanel value={activeTab} index={0}>
        {/* <Link to={"/cms/post/create"}>Item One</Link> */}
        <PostTable />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={2}>
        <TagTable />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={3}>
        <AboutTab />
      </CustomTabPanel>

      <AppButton sx={{ mt: 5, width: "fit-content" }} onClick={logOut}>
        Log out
      </AppButton>
    </Box>
  );
};

export { CmsDashboard };
