import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        textAlign: "center",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography color={theme.palette.secondary.contrastText}>
        2023 - DanhMC
      </Typography>
    </Box>
  );
};

export { Footer };
