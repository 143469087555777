import { useNavigate } from "react-router";
import { Box, Typography, useTheme } from "@mui/material";

import { AppImage } from "../image";
import { AppButton } from "../button";
import { NotFoundImg } from "../../assets";
import { useDocumentTitle } from "../../hooks";

const NotFoundView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  useDocumentTitle("404 :: Not found");

  return (
    <>
      <AppImage
        src={NotFoundImg}
        style={{
          width: "100%",
          opacity: 0.15,
          objectFit: "contain",
        }}
      />
      <Box
        sx={{
          mt: 5,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AppButton
          variant="outlined"
          onClick={() => navigate("/")}
          sx={{ display: "flex", width: "fit-content" }}
        >
          <Typography variant="h6" color={theme.palette.primary.main}>
            Quay về
          </Typography>
        </AppButton>
      </Box>
    </>
  );
};

export { NotFoundView };
