const SECTIONS: any[] = [
  {
    id: "blog",
    title: "Blog",
  },
  {
    id: "gallery",
    title: "Gallery",
  },
  {
    id: "tag",
    title: "Tag",
  },
  {
    id: "about",
    title: "About",
  },
];

export default SECTIONS;
