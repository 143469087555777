import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";

import { AppButton } from "../../../../../../components";
import { ITag, ITagPayload } from "../../../../interface";
import { createNewTag, deleteTag, editTag } from "../../../../api";
import { RequestProcessing, StyledInput } from "../../../../components";
import { selectTagToEdit } from "../../../../../../store/slices/cmsSlice";

export enum TagAction {
  ADD,
  EDIT,
  DELETE,
  NONE,
}

interface IProps {
  action: TagAction;
  onClose: () => void;
  onSuccess: () => void;
}

const ActionDialog = ({ action, onClose, onSuccess }: IProps) => {
  const theme = useTheme();
  const isAdding = action === TagAction.ADD;
  const isDeleting = action === TagAction.DELETE;
  const tagToEdit: ITag = useSelector(selectTagToEdit);
  const {
    reset,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<ITagPayload>();
  const { mutate, isPending, isSuccess, error } = useMutation({
    mutationFn: isAdding
      ? (payload: string) => createNewTag(JSON.parse(payload))
      : isDeleting
      ? (payload: string) => deleteTag(JSON.parse(payload))
      : (payload: string) => editTag(JSON.parse(payload)),
  });

  const onSaveData = (data: ITagPayload) => {
    if (data.label && data.name) {
      if (isAdding) {
        mutate(JSON.stringify(data));
      } else if (!isDeleting) {
        mutate(JSON.stringify({ ...data, id: tagToEdit.id }));
      }
    } else {
      if (isDeleting) {
        mutate(JSON.stringify({ id: tagToEdit.id }));
      }
    }
  };

  useEffect(() => {
    reset({
      name: isDeleting ? "" : tagToEdit?.name,
      label: isDeleting ? "" : tagToEdit?.label,
    });
  }, [tagToEdit]);

  return (
    <>
      <Dialog
        open={true}
        sx={{ zIndex: 9 }}
        onClose={() => onClose()}
        PaperProps={{
          style: {
            width: 400,
            borderRadius: 0,
            boxShadow: "none",
            backgroundColor: theme.palette.background.default,
          },
        }}
        component="form"
        onSubmit={handleSubmit(onSaveData)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: theme.palette.primary.main }}
        >
          {isAdding
            ? "Tạo tag mới"
            : isDeleting
            ? `Xoá tag ${tagToEdit?.id}`
            : "Sửa tag"}
        </DialogTitle>
        <DialogContent sx={{ width: 400, color: "white" }}>
          {isDeleting ? (
            "Bro có chắc muốn xoá tag này không?"
          ) : (
            <>
              <StyledInput
                shrinking
                label="Name"
                theme={theme}
                variant="outlined"
                formopt={{ ...register("name") }}
                style={{ width: "100%", marginTop: 10 }}
                placeholder={"Name"}
              />
              <StyledInput
                shrinking
                label="Label"
                theme={theme}
                variant="outlined"
                formopt={{ ...register("label") }}
                style={{ width: "100%", marginTop: 24 }}
                placeholder={"Label"}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <AppButton sx={{ color: "white" }} onClick={() => onClose()}>
            Hủy
          </AppButton>
          <AppButton
            autoFocus
            type="submit"
            sx={{
              color: isDeleting
                ? theme.palette.error.main
                : theme.palette.primary.main,
            }}
          >
            {isDeleting ? "Xoá" : "Lưu"}
          </AppButton>
        </DialogActions>
      </Dialog>

      <RequestProcessing
        error={error}
        isPending={isPending}
        isSuccess={isSuccess}
        onSuccess={onSuccess}
      />
    </>
  );
};

export { ActionDialog };
