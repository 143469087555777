import axios from "axios";
import { isEmpty, assign } from "lodash";

import { BASE_API_URL } from "../constants";

const singletonEnforcer = Symbol();

class AxiosClient {
  axiosClient: any = undefined;
  static axiosClientInstance: any = undefined;

  constructor(enforcer: any) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Cannot initialize Axios client single instance");
    }

    this.axiosClient = axios.create({
      baseURL: BASE_API_URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    // this.setToken();

    this.axiosClient.interceptors.request.use(
      (configure: any) => {
        return configure;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    this.axiosClient.interceptors.response.use(
      (response: any) => {
        if (response && response.data) {
          return response.data;
        }
        return response;
      },
      (error: any) => {
        if (
          error?.response?.data?.error &&
          Array.isArray(error.response.data.error.errors)
        ) {
          return Promise.reject(error.response.data.error.errors);
        }
        return Promise.reject(error.response);
      }
    );
  }

  static get instance() {
    if (!this.axiosClientInstance) {
      this.axiosClientInstance = new AxiosClient(singletonEnforcer);
    }

    return this.axiosClientInstance;
  }

  async setHeader(userToken = null) {
    this.axiosClient.defaults.headers.common.Authorization = `${userToken}`;
  }

  async clearToken() {
    this.axiosClient.defaults.headers.common.Authorization = "";
  }

  get(resource: string, slug = "", config = {}) {
    const requestURL = isEmpty(slug) ? `${resource}` : `${resource}/${slug}`;
    return this.axiosClient.get(
      requestURL,
      assign(config, this.axiosClient.defaults.headers)
    );
  }

  post(resource: string, data: object, config = {}) {
    return this.axiosClient.post(
      `${resource}`,
      data,
      assign(config, this.axiosClient.defaults.headers)
    );
  }

  update(resource: string, data: object, config = {}) {
    return this.axiosClient.put(
      `${resource}`,
      data,
      assign(config, this.axiosClient.defaults.headers)
    );
  }

  put(resource: string, data: object, config = {}) {
    return this.axiosClient.put(
      `${resource}`,
      data,
      assign(config, this.axiosClient.defaults.headers)
    );
  }

  patch(resource: string, data: object, config = {}) {
    return this.axiosClient.patch(
      `${resource}`,
      data,
      assign(config, this.axiosClient.defaults.headers)
    );
  }

  delete(resource: string, data: object, config = {}) {
    return this.axiosClient.delete(`${resource}`, {
      data,
      ...assign(config, this.axiosClient.defaults.headers),
    });
  }

  // private async setToken() {
  //   const token = await asyncStorage.getItem(TOKEN_NAME);

  //   if (token) {
  //     await this.setHeader(token);
  //   }
  // }
}

export default AxiosClient.instance;
