import { createSlice } from "@reduxjs/toolkit";

interface IInitalState {
  content: string;
}

const initialState: IInitalState = {
  content: "",
};

export const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    updateAboutContent: (state, action) => {
      state.content = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAboutContent } = aboutSlice.actions;

export const selectAboutContent = (state: any) => state.about.content;

export default aboutSlice.reducer;
