import { useDispatch } from "react-redux";
import { Box, Divider, Typography, useTheme } from "@mui/material";

import { IPost } from "../../interfaces";
import { AppLink } from "../../../../components";
import { updateViewingPost } from "../../../../store";

interface IPostItem {
  data: IPost;
  isDetailView?: boolean;
}

const PostItem = ({ data, isDetailView }: IPostItem) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { REACT_APP_BASE_URL } = process.env;

  const updatePostInStore = () => {
    dispatch(
      updateViewingPost({ id: data.id, slug: data.slug, title: data.title })
    );
  };

  const styles = {
    title: {
      width: "fit-content",
      paddingBottom: 2,
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
    divider: {
      pb: 0.5,
      width: "100%",
      fontWeight: "bold",
      borderBottom: `3px dotted ${theme.palette.primary.main}`,
    },
  };

  const _divider = () => {
    return (
      <>
        <Typography
          variant="h5"
          sx={{
            pb: 0.5,
            width: "100%",
            fontWeight: "bold",
            borderBottom: `3px dotted ${theme.palette.primary.main}`,
          }}
        />
        <Typography
          variant="h5"
          sx={{
            pb: 0.5,
            width: "100%",
            fontWeight: "bold",
            borderBottom: `3px dotted ${theme.palette.primary.main}`,
          }}
        />
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: isDetailView ? 0 : 8,
      }}
    >
      <Box sx={{ width: "fit-content" }}>
        {isDetailView ? (
          <Typography variant="h5" sx={styles.title}>
            {data.title}
          </Typography>
        ) : (
          <AppLink to={data.slug} onClick={updatePostInStore}>
            <Typography variant="h5" sx={styles.title}>
              {data.title}
            </Typography>
          </AppLink>
        )}
      </Box>
      {_divider()}
      <Typography
        mt={2}
        color={theme.palette.primary.main}
        sx={{ opacity: 0.8 }}
      >
        {data.createdAt}
      </Typography>
      <Box sx={{ mt: 1, display: "flex", flexDirection: "row" }}>
        {data.tags?.map((item) => {
          return (
            <Box key={item} sx={{ marginRight: 6 }}>
              <AppLink to={`${REACT_APP_BASE_URL}/tag=${item}`}>
                <Typography color={theme.palette.secondary.contrastText}>
                  #{item}
                </Typography>
              </AppLink>
            </Box>
          );
        })}
      </Box>
      {!isDetailView && (
        <>
          <Typography mt={3} color={theme.palette.secondary.light}>
            {data.briefContent}
          </Typography>
          <Box my={2} px={0}>
            <AppLink to={data.slug} onClick={updatePostInStore}>
              <Typography color={theme.palette.primary.main}>XEM →</Typography>
            </AppLink>
          </Box>
          <Box my={1} />
          <Divider
            variant="fullWidth"
            sx={{ backgroundColor: theme.palette.primary.main }}
          />
        </>
      )}
    </Box>
  );
};

export { PostItem };
