import { Controller, useForm } from "react-hook-form";
import { Box, Switch, Typography, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { StyledInput } from "../input";
import { MultipleSelect } from "../multiple-select";

export interface IFormInput {
  title: string;
  tags: number[];
  slug: string;
  brief: string;
  publish: boolean;
}

const PostAdditionalData = forwardRef((_, ref) => {
  const theme = useTheme();
  const {
    watch,
    control,
    register,
    setError,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IFormInput>();
  const watchTitle = watch("title");
  const [slug, setSlug] = useState("");

  const slugify = (title: string) => {
    return (
      title
        ?.toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-") ?? ""
    );
  };

  useImperativeHandle(ref, () => {
    return {
      values: { ...getValues(), slug },
    };
  });

  useEffect(() => {
    setSlug(slugify(watchTitle));
  }, [watchTitle]);

  return (
    <Box
      sx={{
        mb: 3,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Title + Publish */}
      <Box
        sx={{
          mt: 3,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <StyledInput
          theme={theme}
          label="Tiêu đề"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          formopt={{ ...register("title") }}
        />

        <Box
          sx={{
            ml: 2,
            pl: 2,
            width: 260,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography color={"white"}>Hiển thị:</Typography>
          <Switch defaultChecked sx={{ ml: 1 }} {...register("publish")} />
        </Box>
      </Box>

      {/* Slug + Tags */}
      <Box
        sx={{
          mt: 3,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <StyledInput
          shrinking
          value={slug}
          label="Slug"
          theme={theme}
          variant="outlined"
          sx={{ flexGrow: 1 }}
          onChange={(e) => setSlug(e.target.value)}
        />
        <Controller
          control={control}
          name="tags"
          render={({ field: { value, onChange } }) => (
            <MultipleSelect value={value} onChange={onChange} />
          )}
        />
      </Box>

      {/* Brief */}
      <Box
        sx={{
          mt: 3,
          width: "100%",
          display: "flex",
        }}
      >
        <StyledInput
          multiline
          theme={theme}
          label="Brief nội dung"
          variant="outlined"
          sx={{ flexGrow: 1 }}
          formopt={{ ...register("brief") }}
        />
      </Box>
    </Box>
  );
});

export { PostAdditionalData };
