import {
  useMemo,
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import React from "react";
import hljs from "highlight.js";
import { Box } from "@mui/material";
// import "highlight.js/styles/atom-one-light.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

hljs.configure({
  languages: ["javascript", "typescript", "gradle", "kotlin", "sh", "python"],
});

Quill.register("modules/imageResize", ImageResize);

interface IProps {
  defaultValue: string;
}

const Editor = forwardRef(({ defaultValue }: IProps, ref) => {
  const [content, setContent] = useState("");

  const modules = useMemo(
    () => ({
      syntax: {
        highlight: (text) => hljs.highlightAuto(text).value,
      },
      toolbar: {
        // handlers: {
        //   image: handleImageUpload
        // },
        container: [
          [{ header: "1" }, { header: "2" }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }],
          ["link", "image", "video"],
          ["clean", "code-block"],
        ],
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      // imageDrop: true,
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    }),
    []
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot", // #5 Optinal if using custom formats
    "video",
    "width",
    "height",
    "style",
    "alt",
    "color",
    "code",
    "code-block",
    "background",
  ];

  useImperativeHandle(ref, () => ({
    value: content,
    setValue: (value: string) => setContent(value),
  }));

  useEffect(() => {
    if (defaultValue) setContent(defaultValue);
  }, [defaultValue]);

  return (
    <Box sx={{ width: "100%" }}>
      <ReactQuill
        theme="snow"
        value={content}
        modules={modules}
        formats={formats}
        onChange={setContent}
      />
    </Box>
  );
});

export default React.memo(Editor);
