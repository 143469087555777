import { Provider } from "react-redux";
import { Container } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { store } from "./store";
import "./theme/styles/App.css";
import AppRouter from "./router";
import { theme } from "./theme/styles/config";

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <ThemeProvider theme={theme}>
            <Container disableGutters sx={{ padding: 2 }} maxWidth="md">
              <AppRouter />
            </Container>
          </ThemeProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
