import { Box } from "@mui/material";

import { MainLayout } from "../../layout";
import { useDocumentTitle } from "../../hooks";
import { GalleryImageList } from "./components";

const GalleryContainer = () => {
  useDocumentTitle("Bloggy :: Gallery");

  return (
    <MainLayout>
      <Box my={7} />
      <GalleryImageList />
      <Box mb={6} />
    </MainLayout>
  );
};

export { GalleryContainer };
