import { createSlice } from "@reduxjs/toolkit";
import { IPost, ITag } from "../../containers/cms/interface";

interface IInitalState {
  tags: ITag[];
  post: {
    list: IPost[];
    pagination: any;
  };
  selectedTag?: ITag;
  selectedPost?: IPost;
}

const initialState: IInitalState = {
  tags: [],
  post: {
    list: [],
    pagination: undefined,
  },
  selectedTag: undefined,
  selectedPost: undefined,
};

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    updateTagsList: (state, action) => {
      state.tags = action.payload;
    },
    updateSelectedTag: (state, action) => {
      state.selectedTag = action.payload;
    },
    updateSelectedPost: (state, action) => {
      state.selectedPost = action.payload;
    },
    updatePostList: (state, action) => {
      state.post.list = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateTagsList,
  updateSelectedTag,
  updateSelectedPost,
  updatePostList,
} = cmsSlice.actions;

export const selectAllTags = (state: any) => state.cms.tags;
export const selectTagToEdit = (state: any) => state.cms.selectedTag;
export const selectPostList = (state: any) => state.cms.post.list;
export const selectPostToEdit = (state: any) => state.cms.selectedPost;

export default cmsSlice.reducer;
