import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { QUERY_KEYS } from "../constants";
import { getAllPosts } from "../containers/cms/api";
import { IPost } from "../containers/cms/interface";
import { selectPostList, updatePostList } from "../store/slices/cmsSlice";

const useFetchPosts = () => {
  const dispatch = useDispatch();
  const savedPost: IPost[] = useSelector(selectPostList);
  const query = useQuery({
    queryKey: [QUERY_KEYS.GET_POSTS],
    queryFn: getAllPosts,
    retry(failureCount, error) {
      return false;
    },
    staleTime: Infinity,
    enabled: savedPost?.length == 0,
  });

  useEffect(() => {
    if (query.isSuccess) {
      dispatch(updatePostList(query.data?.data));
    }
  }, [query.isSuccess]);

  return {
    isLoading: query.isLoading || query.isRefetching,
    isSuccess: query.isSuccess,
    refetch: query.refetch,
    list: query.data?.data,
  };
};

export { useFetchPosts };
