import { useLocation } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";

import { AppLink } from "../link";

interface NavItem {
  id: string;
  title: string;
  path: string;
  home?: boolean;
}

const NAV_DATA: NavItem[] = [
  {
    id: "blog",
    title: "Blog",
    path: "/",
    home: true,
  },
  {
    id: "gallery",
    title: "Gallery",
    path: "/gallery",
  },
  {
    id: "about",
    title: "About",
    path: "/about",
  },
];

const NavBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const { REACT_APP_BASE_URL } = process.env;

  return (
    <Box
      sx={{
        mt: 1.2,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      {NAV_DATA.map((item: NavItem) => {
        const isCurrentPath = location.pathname.includes(item.id);
        const isHome = location.pathname === "/";

        return (
          <Box key={item.id} sx={{ marginRight: 3 }}>
            <AppLink to={REACT_APP_BASE_URL + item.path}>
              <Typography
                color={
                  isCurrentPath || (isHome && item.home)
                    ? theme.palette.primary.main
                    : theme.palette.secondary.light
                }
              >
                {item.title}
              </Typography>
            </AppLink>
          </Box>
        );
      })}
    </Box>
  );
};

export { NavBar };
