import { ImageList, ImageListItem } from "@mui/material";
import { ImageViewer } from "../image-viewer";
import { useState } from "react";

const data = [
  {
    img: "https://firebasestorage.googleapis.com/v0/b/bloggy-79968.appspot.com/o/theground.jpg?alt=media&token=5f74d03e-bc6c-4eb5-a08f-4bc59b82fd93&_gl=1*1wmd4y3*_ga*MTkzNjA2MDc0My4xNjk3MzU5NjUx*_ga_CW55HF8NVT*MTY5NzM1OTY1MS4xLjEuMTY5NzM1OTk1NC4xMS4wLjA.",
    title: "theground",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/bloggy-79968.appspot.com/o/PXL_20211102_045213002.jpg?alt=media&token=54c56d55-4784-496c-8eeb-ec9b2bb9f2fe&_gl=1*fho8uk*_ga*MTkzNjA2MDc0My4xNjk3MzU5NjUx*_ga_CW55HF8NVT*MTY5NzM2MjU1Ny4yLjEuMTY5NzM2MzAwNy4xOC4wLjA.",
    title: "theground",
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/bloggy-79968.appspot.com/o/IMG_20200825_182906.jpg?alt=media&token=dfa0c613-298d-480b-86be-1a89ce04583a&_gl=1*1f1cu6t*_ga*MTkzNjA2MDc0My4xNjk3MzU5NjUx*_ga_CW55HF8NVT*MTY5NzM2MjU1Ny4yLjEuMTY5NzM2MzAwMi4yMy4wLjA.",
    title: "theground",
  },
];

const GalleryImageList = () => {
  const [viewingIndex, setViewingIndex] = useState(-1);

  const closeViewer = () => {
    setViewingIndex(-1);
  };

  return (
    <>
      <ImageList
        sx={{ width: "100%", height: "auto" }}
        cols={3}
        rowHeight="auto"
      >
        {data.map((item: any, index) => (
          <ImageListItem key={item.img} sx={{ cursor: "pointer" }}>
            <img
              loading="lazy"
              alt={item.title}
              onClick={() => setViewingIndex(index)}
              // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=200&h=200`}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <ImageViewer
        images={data}
        onClose={closeViewer}
        initialIndex={viewingIndex}
      />
    </>
  );
};

export { GalleryImageList };
