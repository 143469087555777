import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { Box, useTheme } from "@mui/material";

import { AppButton } from "../../../../components";
import { AboutDetail } from "../../../about/components";

const AboutTab = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          mb: 4,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <AppButton
          variant="contained"
          color={"primary"}
          onClick={() => navigate("about/edit")}
          sx={{
            [theme.breakpoints.down("sm")]: {
              p: 1.2,
              width: "100%",
            },
          }}
          startIcon={<EditIcon />}
        >
          Sửa
        </AppButton>
      </Box>
      <AboutDetail />
    </Box>
  );
};

export { AboutTab };
