import { Box } from "@mui/material";
import { AppSkeleton } from "../../../../components";

const AboutSkeleton = () => {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <AppSkeleton height={80} width="100%" variant="rectangular" />
      <AppSkeleton
        sx={{ mt: 4 }}
        height={120}
        width="100%"
        variant="rectangular"
      />
      <AppSkeleton
        sx={{ mt: 4 }}
        height={120}
        width="100%"
        variant="rectangular"
      />
      <AppSkeleton
        sx={{ mt: 4 }}
        height={120}
        width="100%"
        variant="rectangular"
      />
    </Box>
  );
};

export { AboutSkeleton };
