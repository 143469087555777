import { ReactNode } from "react";
import { Footer, Header } from "../../components";
import { useDocumentTitle } from "../../hooks";

interface IProps {
  children: ReactNode;
}

const CMSLayout = ({ children }: IProps) => {
  useDocumentTitle("Bloggy :: CMS");

  return (
    <>
      <Header />
      {children}
      {/* <Footer /> */}
    </>
  );
};

export { CMSLayout };
