import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import { LoginForm } from "./components";
import { CmsDashboard } from "./dashboard";
import { selectLoggedInState } from "../../store/slices/appSlice";

const CmsContainer = () => {
  const isAuth = useSelector(selectLoggedInState);

  return (
    <>
      <Box my={isAuth ? 7 : 14} />
      {isAuth ? (
        <CmsDashboard />
      ) : (
        <>
          <LoginForm />
          <Box my={10} />
        </>
      )}
    </>
  );
};

export { CmsContainer };
