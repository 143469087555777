import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Editor from "../editor";
import {
  selectAboutContent,
  updateAboutContent,
} from "../../../../store/slices/aboutSlice";
import { AppButton } from "../../../../components";
import { useDocumentTitle } from "../../../../hooks";
import { ICreatePostPayload } from "../../interface";
import { createPost, saveAboutContent } from "../../api";
import { RequestProcessing } from "../request-processing";
import { IFormInput, PostAdditionalData } from "../post-additional-data";

interface IProps {
  about: boolean;
}

interface IEditorDialog {
  show: boolean;
  type: DialogType;
}

type DialogType = "exit" | "save" | null;

const dialogText = {
  content: {
    exit: "Có chắc chắn muốn thoát editor không vậy?",
    save: "Có chắc lưu lại chỉnh sửa này không?",
    add: "Có chắc tạo bài viết này không?",
  },
  action: {
    exit: "Thoát",
    save: "Lưu",
  },
};

const EditorPage = ({ about }: IProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const editorRef = useRef<any>();
  const postInfoRef = useRef<any>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation({
    mutationFn: about
      ? (content: string) => saveAboutContent({ content })
      : (payload: string) => createPost(JSON.parse(payload)),
  });
  const content = useSelector(about ? selectAboutContent : selectAboutContent);
  const [dialog, setDialog] = useState<IEditorDialog>({
    show: false,
    type: "exit",
  });
  useDocumentTitle(about ? "CMS :: Edit about" : "CMS :: Create post");

  const toggleDialog = () => {
    setDialog({ ...dialog, show: !dialog.show });
  };

  const onSaveData = () => {
    if (about) {
      mutation.mutate(editorRef.current?.value);
      toggleDialog();
    } else {
      const editorContent = editorRef.current?.value;
      const info: IFormInput = postInfoRef.current?.values;
      console.log(info);
      if (
        editorContent &&
        info.title &&
        info.slug &&
        info.brief &&
        info.tags?.length > 0
      ) {
        const payload: ICreatePostPayload = {
          slug: info.slug,
          title: info.title,
          brief: info.brief,
          tags: info.tags,
          publish: info.publish,
          content: editorContent,
        };

        mutation.mutate(JSON.stringify(payload));
        toggleDialog();
      } else {
        toggleDialog();
        enqueueSnackbar("Chưa đủ thông tin bro!!!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }
  };

  useEffect(() => {
    if (mutation.isSuccess && about) {
      dispatch(updateAboutContent(mutation.data?.data?.content));
    }
  }, [mutation.isSuccess]);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  return (
    <>
      <Box mt={4} />
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {/* Nav bar */}
        <Box
          sx={{
            mb: 2,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            size="large"
            color="primary"
            onClick={() => setDialog({ show: true, type: "exit" })}
          >
            <ArrowBackIcon />
          </IconButton>

          <Typography ml={3} fontWeight="bold" color="white">
            {about ? "Sửa nội dung About" : "Tạo bài viết mới"}
          </Typography>
        </Box>

        {/* Post additional info */}
        {!about && <PostAdditionalData ref={postInfoRef} />}

        {/* Editor */}
        <Editor ref={editorRef} defaultValue={content} />

        <AppButton
          variant="contained"
          color={"primary"}
          onClick={() => setDialog({ show: true, type: "save" })}
          sx={{ mt: 4, alignSelf: "flex-end" }}
          startIcon={<SaveIcon />}
        >
          Lưu
        </AppButton>
      </Box>

      <Dialog
        open={dialog.show}
        onClose={toggleDialog}
        PaperProps={{
          style: {
            borderRadius: 0,
            boxShadow: "none",
            backgroundColor: theme.palette.background.default,
          },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ color: theme.palette.primary.main }}
        >
          Khoan nha bro!
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ color: "white" }}
            id="alert-dialog-description"
          >
            {dialog.type == "exit"
              ? dialogText.content.exit
              : about
              ? dialogText.content.save
              : dialogText.content.add}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton sx={{ color: "white" }} onClick={toggleDialog}>
            Hủy
          </AppButton>
          <AppButton
            autoFocus
            onClick={() => {
              if (dialog.type == "exit") {
                navigate(-1);
              } else if (dialog.type == "save") {
                onSaveData();
              }
            }}
            sx={{
              color:
                dialog.type == "exit"
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
            }}
          >
            {dialog.type == "exit"
              ? dialogText.action.exit
              : dialogText.action.save}
          </AppButton>
        </DialogActions>
      </Dialog>

      <RequestProcessing
        error={mutation.error}
        isPending={mutation.isPending}
        isSuccess={mutation.isSuccess}
        onSuccess={() => navigate(-1)}
      />
    </>
  );
};

export { EditorPage };
