import { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";

import { AppButton } from "../button";
import { theme } from "../../theme/styles/config";

interface IProps {
  show: boolean;
  title: string;
  isSuccess: boolean;
  action: () => void;
}

const ResultDialog = ({ show, title, isSuccess, action }: IProps) => {
  const [open, setOpen] = useState(show);

  const closeDialog = () => {
    setOpen(false);
    action();
  };

  useEffect(() => {
    if (show != open) {
      setOpen(show);
    }
  }, [show]);

  return (
    <Dialog
      open={open}
      sx={{ zIndex: 11 }}
      onClose={closeDialog}
      PaperProps={{
        style: {
          borderRadius: 0,
          boxShadow: "none",
          backgroundColor: theme.palette.background.default,
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        sx={{
          py: 4,
          px: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isSuccess ? (
          <CheckCircleIcon color="primary" sx={{ fontSize: 76 }} />
        ) : (
          <CancelIcon color="error" sx={{ fontSize: 76 }} />
        )}
        <DialogContentText
          sx={{ color: "white", mt: 2, mb: 4 }}
          id="alert-dialog-description"
        >
          {title}
        </DialogContentText>
        <AppButton
          variant="contained"
          onClick={closeDialog}
          sx={{ width: "100%", py: 1, fontWeight: "bold" }}
        >
          OKAY
        </AppButton>
      </DialogContent>
    </Dialog>
  );
};

export { ResultDialog };
