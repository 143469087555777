import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Theme,
  useTheme,
} from "@mui/material";
import { forwardRef, useState } from "react";

import { ITag } from "../../interface";
import { useFetchTags } from "../../../../hooks";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface IProps {
  value: any;
  onChange: any;
}

const MultipleSelect = forwardRef(({ value, onChange }: IProps, ref) => {
  const theme = useTheme();
  const { tags } = useFetchTags();
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const getStyles = (name: string, personName: string[]) => {
    return {
      fontWeight: personName.indexOf(name) === -1 ? "400" : "700",
    };
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    onChange(event);
  };

  return (
    <FormControl sx={{ ml: 2, width: 260 }}>
      <InputLabel sx={{ color: "#fff" }}>Tags</InputLabel>
      <Select
        multiple
        value={selectedTags}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              width: 250,
              borderRadius: 0,
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: theme.palette.primary.main,
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: theme.palette.grey[200],
              },
              "& .MuiMenuItem-root.Mui-selected:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            },
          },
        }}
        sx={{
          color: "white",
          ".MuiOutlinedInput-notchedOutline": {
            borderRadius: 0,
            borderColor: "white",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderRadius: 0,
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderRadius: 0,
            borderColor: theme.palette.primary.main,
          },
          ".MuiSvgIcon-root ": {
            fill: "white !important",
          },
        }}
        input={<OutlinedInput label="Name" sx={{ color: "#fff" }} />}
      >
        {tags?.map((tag: ITag) => (
          <MenuItem
            key={tag.id}
            value={tag.id}
            style={getStyles(tag.name, selectedTags)}
          >
            {tag.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export { MultipleSelect };
