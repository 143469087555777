const MAX_WIDTH = {
  DESKTOP: 1080,
  TABLET: 740,
  MOBILE: 400,
};
const { REACT_APP_API_URL } = process.env;
const QUERY_KEYS = {
  GET_ABOUT: "get-about",
  EDIT_ABOUT: "edit-about",
  GET_TAGS: "get-tags",
  GET_POSTS: "get-posts",
};

export { MAX_WIDTH, QUERY_KEYS, REACT_APP_API_URL as BASE_API_URL };
