import { Box, Typography, useTheme } from "@mui/material";
import { AppButton } from "../../../../components";

const LoadMoreButton = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mb: 6,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AppButton
        variant="outlined"
        sx={{
          width: "fit-content",
        }}
      >
        <Typography
          sx={{ textTransform: "none" }}
          color={theme.palette.primary.main}
        >
          Xem thêm
        </Typography>
      </AppButton>
    </Box>
  );
};

export { LoadMoreButton };
