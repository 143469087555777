import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Backdrop, Box, IconButton, useTheme } from "@mui/material";
import { AppImage } from "../../../../components";

interface IProps {
  initialIndex: number;
  images: any[];
  onClose: () => void;
}

const ImageViewer = ({ images, initialIndex, onClose }: IProps) => {
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const viewOtherImage = (next: boolean) => {
    if (next) {
      currentIndex < images.length - 1 && setCurrentIndex(currentIndex + 1);
    } else {
      currentIndex != 0 && setCurrentIndex(currentIndex - 1);
    }
  };

  useEffect(() => {
    if (initialIndex != currentIndex) {
      setCurrentIndex(initialIndex);
    }
  }, [initialIndex]);

  return currentIndex > -1 ? (
    <Backdrop
      open
      sx={{
        zIndex: 12,
        display: "flex",
        flexDirection: "column",
        // alignItems: "flex-start",
        // justifyContent: "flex-start",
      }}
      onClick={onClose}
    >
      <AppImage
        src={images[currentIndex].img}
        onClick={(e) => e.stopPropagation()}
        style={{ width: 900, height: 500, objectFit: "contain" }}
      />
      <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            viewOtherImage(false);
          }}
        >
          <ArrowBackIosNewIcon
            color={currentIndex == 0 ? "disabled" : "primary"}
          />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="close"
          onClick={onClose}
          sx={{ mx: 2, border: `solid ${theme.palette.primary.main} 1px` }}
        >
          <CloseIcon color="primary" />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={(e) => {
            e.stopPropagation();
            viewOtherImage(true);
          }}
        >
          <ArrowForwardIosIcon
            color={currentIndex == images.length - 1 ? "disabled" : "primary"}
          />
        </IconButton>
      </Box>
    </Backdrop>
  ) : (
    <></>
  );
};
export { ImageViewer };
