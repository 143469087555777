import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";

const AppImage = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [props.src]);

  return (
    <>
      {!loaded && (
        <Box
          sx={{
            display: "flex",
            height: props.style?.height,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <img
        {...props}
        style={loaded ? props.style : { width: 0, height: 0 }}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

export { AppImage };
