import { Box } from "@mui/material";
import { IPost } from "../../interfaces";
import { PostItem } from "../post-item";

interface IProps {
  data: IPost[];
}

const PostList = ({ data }: IProps) => {
  return (
    <>
      {data.map((item) => {
        return <PostItem key={item.id} data={item} />;
      })}
    </>
  );
};

export { PostList };
