import { Button, ButtonProps } from "@mui/material";

interface IButton extends ButtonProps {}

const AppButton = (props: IButton) => {
  const styles: any = [
    {
      borderRadius: 0,
      cursor: "pointer",
      width: "min-content",
      alignItems: "center",
      justifyContent: "center",
    },
    props.sx,
  ];

  return <Button disableRipple {...props} sx={styles} />;
};

export { AppButton };
