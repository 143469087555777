import { useEffect } from "react";
import { useSnackbar } from "notistack";

import { LoadingOverlay, ResultDialog } from "../../../../components";

interface IProps {
  isPending: boolean;
  isSuccess: boolean;
  error?: Error | null;
  onSuccess: any;
}

const RequestProcessing = ({
  isPending,
  isSuccess,
  error,
  onSuccess,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error && error?.message) {
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [error]);

  return (
    <>
      {isPending && <LoadingOverlay title="Đang thực hiện" />}

      {isSuccess && (
        <ResultDialog
          show
          isSuccess
          title="Thao tác thành công!"
          action={onSuccess}
        />
      )}
    </>
  );
};

export { RequestProcessing };
