import { IPost } from "../../interfaces";

const DATA: IPost[] = [
  {
    id: "1",
    title: "Hello Friend",
    createdAt: "2022-01-25",
    briefContent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec interdum metus. Aenean rutrum ligula sodales ex auctor, sed tempus dui mollis. Curabitur ipsum dui, aliquet nec commodo at, tristique eget ante.",
    tags: ["Tam su", "Linh tinh"],
    slug: "/blog/hello-friend",
  },
  {
    id: "2",
    title: "Hệ thống ném vệ tinh vào vũ trụ ở tốc độ 7.408 km/h",
    createdAt: "2022-01-25",
    briefContent:
      "Hệ thống ném vệ tinh vào vũ trụ ở tốc độ 7.408 km/h. Aenean rutrum ligula sodales ex auctor, sed tempus dui mollis. Curabitur ipsum dui, aliquet nec commodo at, tristique eget ante.",
    tags: ["Tam su"],
    slug: "/blog/he-thong",
  },
];

export default DATA;
